"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmojisGameCtrl = void 0;
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const utils_1 = require("@src/shared/utils");
const defaultData = {
    size: '4x4',
    score: 0,
    startWith: 0,
    records: {
        '3x3': undefined,
        '4x4': undefined,
        '5x5': undefined,
        '6x6': undefined,
    }
};
class EmojisGameCtrl {
    constructor($scope, $location, $timeout, ModalServiceFactory, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$location = $location;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.emojis = ['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '🥲', '🥹', '☺️', '😊', '😇', '🙂', '🙃', '😉', '😌',
            '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎', '🥸', '🤩', '🥳', '😏',
            '😒', '😞', '😔', '😟', '😕', '🙁', '☹️', '😣', '😖', '😫', '😩', '🥺', '😢', '😭', '😮', '‍💨', '😤', '😠', '😡',
            '🤬', '🤯', '😳', '🥵', '🥶', '😱', '😨', '😰', '😥', '😓', '🫣', '🤗', '🤔', '🫢', '🤭', '🤫', '🤥', '😶', '😐',
            '😑', '😬', '🙄', '😯', '😦', '😧', '😮', '😲', '🥱', '😴', '🤤', '😪', '😵', '😵', '‍💫', '🤐', '🥴', '🤢', '🤮',
            '🤧', '😷', '🤒', '🤕', '🤑', '🤠'];
        this.sizes = {
            '3x3': {
                title: '3×3',
                size: 3,
                ttl: 30,
                offset: 3,
            },
            '4x4': {
                title: '4×4',
                size: 4,
                ttl: 60,
                offset: 5,
            },
            '5x5': {
                title: '5×5',
                size: 5,
                ttl: 90,
                offset: 10,
            },
            '6x6': {
                title: '6×6',
                size: 6,
                ttl: 60 * 2,
                offset: 20,
            },
        };
        this.data = JSON.parse(localStorage.getItem(`${this.constructor.name}_data`) || JSON.stringify(defaultData));
        this.startWith = this.data.startWith ? this.data.startWith : this.sizes[this.data.size].ttl;
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this._flip = new rxjs_1.BehaviorSubject(0);
        this.timeRemaining = EmojisGameCtrl._formatTime(this.startWith);
        this.timerWork = false;
        this.finished = false;
        this.game$ = new rxjs_1.Subject();
        this.clickNumber$ = new rxjs_1.Subject();
        console.log(this.emojis.length);
        const size = parseInt(this.$location.search()['size']);
        if (size) {
            if (this.sizes[this.data.size].size != size) {
                for (let key of Object.keys(this.sizes)) {
                    if (this.sizes[key].size == size) {
                        this.newGame(key, true);
                        break;
                    }
                }
            }
        }
        this.$scope.$watch('$ctrl.finished', (finished) => {
            if (finished) {
                this._stopTimer.next();
                this._startTimer.next(null);
                localStorage.setItem(`${this.constructor.name}_data`, JSON.stringify(Object.assign(Object.assign({}, this.data), { grid: undefined })));
            }
        });
        this.$scope.$watch('$ctrl.data', (data) => {
            if (data.size != defaultData.size) {
                $location.search('size', this.sizes[data.size].size);
            }
            else {
                $location.search('size', null);
            }
            if (this.timerWork && data.grid) {
                localStorage.setItem(`${this.constructor.name}_data`, JSON.stringify(data));
            }
            else {
                // localStorage.setItem(`${this.constructor.name}_data`, JSON.stringify({
                //     ...data,
                //     grid: undefined
                // }))
            }
        }, true);
    }
    $onInit() {
        this.game$.pipe((0, operators_1.startWith)(this.data), (0, operators_1.tap)((data) => {
            const size = this.sizes[data.size];
            document.documentElement.style.setProperty('--grid-size', size.size.toString());
            if (data.grid == undefined) {
                let emojis = ng.copy(this.emojis);
                (0, utils_1.shuffle)(emojis);
                const firstPosition = (0, utils_1.randomInt)(0, size.size * size.size - 1);
                const secondEmoji = emojis[firstPosition];
                let secondPosition = (0, utils_1.randomInt)(0, size.size * size.size - 1);
                while (secondPosition == firstPosition) {
                    secondPosition = (0, utils_1.randomInt)(0, size.size * size.size - 1);
                }
                data.secondEmoji = secondEmoji;
                data.grid = Array.from({ length: size.size }, (_, x) => {
                    return Array.from({ length: size.size }, (_, y) => {
                        let emoji = emojis.shift();
                        if ((x * size.size + y) === secondPosition) {
                            emoji = secondEmoji;
                        }
                        return {
                            emoji: emoji,
                            selected: false,
                        };
                    });
                });
            }
            return data;
        }), (0, operators_1.switchMap)(() => {
            let selectedCol = null;
            for (let row of this.data.grid) {
                for (let col of row) {
                    if (col.selected) {
                        col.selected = false;
                        selectedCol = col;
                        break;
                    }
                }
            }
            return this.clickNumber$.pipe((0, operators_1.startWith)({ col: selectedCol }), (0, operators_1.filter)((value) => (value.col != null) && !this.data.stop), (0, operators_1.map)((value, index) => {
                value.col.selected = value.col.selected == undefined ? true : !value.col.selected;
                if ([0, 1].indexOf(index) > -1) {
                    this._startTimer.next(true);
                }
                return value;
            }), (0, operators_1.distinctUntilChanged)((prev, curr) => prev.col === curr.col), (0, operators_1.pairwise)(), (0, operators_1.map)((items) => {
                if (items[0].col.emoji != items[1].col.emoji) {
                    items[0].col.selected = false;
                    return false;
                }
                else {
                    this.data.stop = true;
                    return true;
                }
            }), (0, operators_1.delay)(100), (0, operators_1.tap)((stop) => {
                if (stop == true) {
                    this.data.score += 1;
                    this.data.stop = false;
                    this.data.records[this.data.size] = Math.max(this.data.records[this.data.size] || 0, this.data.score);
                    this.data.grid = undefined;
                    this.SoundService.play('win');
                    this.game$.next(this.data);
                    this._flip.next(this.sizes[this.data.size].offset);
                }
            }), (0, operators_1.takeUntil)(this._stopTimer));
        })).subscribe();
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            if (!value) {
                this.timeRemaining = EmojisGameCtrl._formatTime(this.startWith);
            }
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this._makeClock();
            }
            return rxjs_1.EMPTY;
        })).subscribe();
    }
    newGame(size, force) {
        if (force) {
            if (size) {
                this.data.size = size;
            }
            this._flip.next(0);
            this.data.score = 0;
            this.data.grid = undefined;
            this.data.stop = false;
            this.data.startWith = 0;
            localStorage.removeItem(`${this.constructor.name}_data`);
            this._stopTimer.next(null);
            this.startWith = this.sizes[this.data.size].ttl;
            this.timeRemaining = EmojisGameCtrl._formatTime(this.startWith);
            this.finished = false;
            this._startTimer.next(null);
            this.game$.next(this.data);
        }
        else {
            if (this.finished || (this.startWith == this.sizes[this.data.size].ttl) || !this.ConfigService.cookieSettings.show_timer) {
                this.newGame(size, true);
            }
            else {
                const timerWork = this.timerWork;
                this._resume.next('hidden');
                this.ModalServiceFactory.open({
                    id: 'game_status',
                    component: "confirm-comp",
                    scope: this.$scope,
                    extraContext: {}
                }).then((result) => {
                    this._resume.next('visible');
                    if (result == 'newGame') {
                        this.newGame(size, true);
                    }
                    else {
                        if (timerWork) {
                            this._startTimer.next(null);
                            this._startTimer.next(true);
                        }
                    }
                });
            }
        }
    }
    pauseGame() {
        if (this.ConfigService.cookieSettings.show_timer) {
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "pause-comp",
                scope: this.$scope,
                strategy: "if_close_all"
            }).then(() => {
                this._resume.next('visible');
            });
        }
    }
    clickEmoji(col, event) {
        this.clickNumber$.next({
            col: col,
            event: event
        });
    }
    _makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume, this._flip]).pipe((0, operators_1.switchMap)(([v1, v2, offset]) => {
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith + offset)));
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.map)(([i, startWith]) => {
            console.log(startWith);
            const sec = startWith - i;
            this.$timeout(() => {
                if (sec >= 0) {
                    this.timerWork = true;
                    this.startWith = sec;
                    this.data.startWith = sec;
                    this.timeRemaining = EmojisGameCtrl._formatTime(sec);
                }
                else {
                    this.endGame();
                }
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
    endGame() {
        if (this.ConfigService.cookieSettings.show_timer) {
            this.timerWork = false;
            this.finished = true;
            this.SoundService.play('lose');
            this.newGame(this.data.size, true);
            this.ModalServiceFactory.open({
                id: 'game_status',
                template: require("../end_game.ng.html"),
                component: "alert-comp",
                scope: this.$scope,
                extraContext: {
                    timeRemaining: this.timeRemaining,
                    score: this.data.score,
                    record: this.data.records[this.data.size],
                }
            }).then((result) => {
                // if (result == 'newGame') {
                //     this.newGame(this.data.size, true)
                // }
            });
        }
    }
    static _formatTime(sec) {
        if (sec >= 3600) {
            return new Date(sec * 1000).toISOString().substr(11, 8);
        }
        return new Date(sec * 1000).toISOString().substr(14, 5);
    }
}
exports.EmojisGameCtrl = EmojisGameCtrl;
EmojisGameCtrl.$inject = [
    '$scope',
    '$location',
    '$timeout',
    'ModalServiceFactory',
    'ConfigService',
    'SoundService',
];
